import React, { useState } from "react";
import {
  Switch,
  BrowserRouter,
  Route,
  Redirect,
  useLocation,
  useHistory
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./globalStyles";
import { lightTheme, darkTheme } from "./Themes";
import Answer from "./components/Answer";
import Question from "./components/Question";
import AnimatedIcon from "./components/AnimatedIcon";
import "./App.css";

const getConfirmPath = currentPath => {
  switch (currentPath) {
    case "alreadyOwnBitcoin":
      return "wantMoreBitcoin";
    case "believeInBitcoin":
      return "buyBitcoin";
    case "believeInFiat":
      return "trustCentralBanks";
    case "trustCentralBanks":
      return "bankingCrisisSolved";
    case "bankingCrisisSolved":
      return "believeInGoldStandard";
    case "believeInGoldStandard":
      return "buyBitcoin";
    case "earnMoney":
      return "buyBitcoin";
    case "wantMoreBitcoin":
      return "buyBitcoin";
    case "bitcoinUnder500k":
      return "buyBitcoin";
    default:
      return "buyBitcoin";
  }
};

const getRefusePath = currentPath => {
  switch (currentPath) {
    case "alreadyOwnBitcoin":
      return "believeInBitcoin";
    case "believeInBitcoin":
      return "believeInFiat";
    case "believeInFiat":
      return "buyBitcoin";
    case "trustCentralBanks":
      return "buyBitcoin";
    case "bankingCrisisSolved":
      return "buyBitcoin";
    case "believeInGoldStandard":
      return "earnMoney";
    case "earnMoney":
      return "buyBitcoin";
    case "wantMoreBitcoin":
      return "bitcoinUnder500k";
    case "bitcoinUnder500k":
      return "believeInBitcoin";
    default:
      return "alreadyOwnBitcoin";
  }
};

const Root = () => {
  const [theme, setTheme] = useState("dark");

  return (
    <ThemeProvider theme={theme === "dark" ? darkTheme : lightTheme}>
      <BrowserRouter>
        <GlobalStyles />
        <App theme={theme} setTheme={setTheme} />
      </BrowserRouter>
    </ThemeProvider>
  );
};

const App = ({ theme, setTheme }) => {
  const [lastPath, setLastPath] = useState();
  const history = useHistory();
  const location = useLocation();
  const currentPath = location.pathname.substring(1);
  const navigateTo = newPath => {
    setLastPath(currentPath);
    history.push(newPath);
  };
  const themeToggler = () => {
    theme === "light" ? setTheme("dark") : setTheme("light");
  };

  return (
    <div className="App">
      <div className="darkMode">
        <AnimatedIcon
          isDarkMode={theme === "dark"}
          toggleTheme={themeToggler}
        />
      </div>
      <Switch>
        <Route exact path="/">
          <Redirect to="/alreadyOwnBitcoin" />
        </Route>
        <Route path="/buyBitcoin">
          <Answer lastPath={lastPath} />
        </Route>
        <Route>
          <Question
            question={currentPath}
            handleConfirm={() => navigateTo(getConfirmPath(currentPath))}
            handleReject={() => navigateTo(getRefusePath(currentPath))}
          />
        </Route>
      </Switch>
    </div>
  );
};

export default Root;
