import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from "./locales/en/translations.json";
import nlTranslation from "./locales/nl/translations.json";

const resources = {
    en: {
        translation: enTranslation
    },
    nl: {
        translation: nlTranslation
    }
};

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        fallbackLng: 'nl',
        debug: true,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;