import React from "react";
import LazyLoad from "react-lazy-load";
import { useTranslation } from "react-i18next";
import bitcoinLogo from "../bitcoin.png";

import "./Question.css";

const Question = ({ question, handleConfirm, handleReject }) => {
  const { t } = useTranslation();
  return (
    <>
      <LazyLoad>
        <img
          className="bitcoinLogo"
          src={bitcoinLogo}
          alt="You probably want to buy bitcoin"
        />
      </LazyLoad>
      <p>{t(question)}</p>
      <div className="buttons">
        <button className="confirm" onClick={handleConfirm}>
          {t("confirm")}
        </button>
        <button className="reject" onClick={handleReject}>
          {t("reject")}
        </button>
      </div>
    </>
  );
};

export default Question;
