import React from "react";
import LazyLoad from "react-lazy-load";
import { useTranslation } from "react-i18next";
import BtcDirectLogo from "../logo.svg";

import "./Answer.css";

const customImages = [
  "bankingCrisisSolved",
  "believeInBitcoin",
  "believeInFiat",
  "believeInGoldStandard",
  "bitcoinUnder500k"
];

const Answer = ({ lastPath }) => {
  const image = customImages.includes(lastPath) ? lastPath : "bitcoin";
  const { t } = useTranslation();
  const handleClick = () => {
    window.location.href = "https://www.btcdirect.eu";
  };
  return (
    <>
      <LazyLoad>
        <img
          className="bitcoinLogo"
          src={`${process.env.PUBLIC_URL}/img/${image}.png`}
          alt="Just buy bitcoin already"
        />
      </LazyLoad>
      <p>{t("buyBitcoin")}</p>
      <button className="info" onClick={handleClick}>
        <img src={BtcDirectLogo} alt="Just buy bitcoin already" />
      </button>
      <div className="disclaimer">{t("notFinancialAdvice")}</div>
    </>
  );
};

export default Answer;
